// src/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA8Rvb3BfTaEeUCSweTvAb9mGifXAALW84",
    authDomain: "lendesca-2024-cloud-functions.firebaseapp.com",
    projectId: "lendesca-2024-cloud-functions",
    storageBucket: "lendesca-2024-cloud-functions.appspot.com",
    messagingSenderId: "774489067472",
    appId: "1:774489067472:web:274456edb03a4aee6373d5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export the auth instance
export const auth = getAuth(app);



