<template>
  <router-view></router-view>
  <Toast/>
  <DynamicDialog/>
  <ConfirmDialog group="templating">
    <template #container="{ message, acceptCallback, rejectCallback }">
      <div class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded">
        <span class="font-bold text-2xl block mb-2 mt-6">{{ message.header }}</span>

        <p class="mb-0">{{ message.message }}</p>
        <div class="flex items-center gap-2 mt-6">
          <Button class="primary-outline-button"
                  severity="secondary"
                  label="Cancel"
                  outlined
                  @click="rejectCallback"
          />
          <Button class="primary-button"
                  label="Save"
                  @click="acceptCallback"
          />
        </div>
      </div>
    </template>
  </ConfirmDialog>
  <!-- Spinner Overlay -->
  <div v-if="isLoading" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <ProgressSpinner/>
  </div>
</template>

<script setup lang="ts">
import DynamicDialog from 'primevue/dynamicdialog';
import ConfirmDialog from 'primevue/confirmdialog';

import Toast from 'primevue/toast';
import {ref} from "vue";
import apiClient from "@/services/axios.service";
import ProgressSpinner from 'primevue/progressspinner'; // Use PrimeVue spinner

const isLoading = ref(false);

// Request Interceptor
apiClient.interceptors.request.use(config => {
  isLoading.value = true;  // Set loading flag to true when a request starts
  return config;
}, error => {
  isLoading.value = false;
  return Promise.reject(error);
});

// Response Interceptor
apiClient.interceptors.response.use(response => {
  isLoading.value = false;  // Reset loading flag when a response is received
  return response;
}, error => {
  isLoading.value = false;
  return Promise.reject(error);
});


console.log('Version: 1.0.8')

</script>
